<template>
  <div>
    <h2 class="text-uppercase text-lg mb-6">How to use this portal</h2>
    <div class="md-layout md-gutter">
      <div class="md-layout-item md-size-25">
        <ListNavigate
          :items="items"
          @on-click="onChangeContent"
        />
      </div>
      <div class="md-layout-item md-size-75">
        <h1 class="mt-0 text-3xl">{{ content.subtitle }}</h1>
        <p class="text-lg line-height-33 my-3">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Veniam accusantium voluptates unde sit cupiditate! Cum est vel, natus quibusdam itaque veritatis quaerat numquam! Facere facilis aspernatur error doloremque, iste fugiat laborum, ut consectetur, natus dolor vero excepturi cum aliquid nisi nulla deleniti temporibus. Reprehenderit, recusandae magnam! Eos error distinctio ut libero, ratione officiis doloremque magnam, assumenda sit aut eveniet non sint laudantium! Minima repudiandae atque dignissimos quisquam autem, voluptate necessitatibus quaerat eligendi praesentium cupiditate fugit sunt repellendus nihil minus deleniti labore ab dolore molestiae accusamus iste quia fugiat? Esse quas ullam ab quia tempore sint deserunt neque quisquam, suscipit laboriosam praesentium magnam harum nostrum mollitia magni. Ut officia, odio autem aliquid ullam at reiciendis, assumenda corrupti minima, rem pariatur fugiat vel quibusdam veniam eaque sit illum sed accusamus? Ex perspiciatis vero soluta deleniti nobis ducimus saepe exercitationem facere, fuga tempore quod dolore, nemo laudantium sit eos nisi, a doloribus odit.</p>
      </div>
    </div>
    <div class="md-layout md-gutter my-6">
      <div class="md-layout-item md-size-75">
        <iframe class="md-image" width="100%" height="460" src="https://www.youtube.com/embed/l5d67j3CAWE" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
      </div>
      <div class="md-layout-item md-size-25">
        <p class="text-lg line-height-33 my-3">
          Lorem, ipsum dolor sit amet consectetur adipisicing elit. Tenetur, deleniti molestias, asperiores facilis corporis labore repudiandae quisquam quam omnis dicta voluptatum quia ipsam inventore vel necessitatibus eius doloremque rerum adipisci. facilis corporis labore repudiandae quisquam quam omnis dicta voluptatum quia ipsam inventore vel necessitatibus eius doloremque rerum adipisci.
        </p>
      </div>
    </div>
    <div class="md-layout md-gutter">
      <div class="md-layout-item">
        <p class="text-lg line-height-33 my-3">Lorem ipsum dolor, sit amet consectetur adipisicing elit. Maiores, dolorum officiis inventore eligendi corrupti earum molestiae aut iure perspiciatis cupiditate ipsa dolores consequuntur at expedita sit ducimus quas, error minima sint maxime provident consequatur. Tenetur sed tempora optio rerum officiis vitae repellat, quo voluptate deserunt cupiditate ex beatae dolor consectetur voluptatem sapiente libero eius? Ipsum, quo maiores. Consequatur mollitia voluptatem, non numquam ut aut deleniti voluptates autem dolorum minus, dolores obcaecati debitis. Eligendi provident nihil consectetur dolores quis nisi aperiam beatae repudiandae sit, eos quia dignissimos quae itaque quam odit obcaecati nam incidunt repellat? Impedit quia doloribus itaque nemo distinctio architecto suscipit id quibusdam ducimus veritatis culpa, quos accusantium ratione sed vero assumenda. Error ullam consequatur incidunt laborum ut eius et animi illum, recusandae illo repellendus placeat nam. Voluptates, vitae. Voluptas exercitationem ipsa quas, numquam minus voluptatum earum quidem doloribus possimus blanditiis velit, molestiae, aut accusantium reiciendis at nam nihil!</p>
      </div>
    </div>
  </div>
</template>

<script>
import ListNavigate from "@/components/molecule/top-menu/ListNavigate";
export default {
  components: {
    ListNavigate
  },
  data() {
    return {
      items: [
        {id: 1, title: 'Chapter 1', subtitle: 'Create Account'},
        {id: 2, title: 'Chapter 2', subtitle: 'Practice Question'},
        {id: 3, title: 'Chapter 3', subtitle: 'Mock Exam'},
        {id: 4, title: 'Chapter 4', subtitle: 'Take plan for teacher'},
        {id: 5, title: 'Chapter 5', subtitle: 'View report'},
      ],
      content: {}
    }
  },
  methods: {
    onChangeContent(index) {
      this.content = this.items[index];
    }
  },
  mounted() {
    this.content = this.items[0];
  }
}
</script>

<style>

</style>