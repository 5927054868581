<template>
    <div class="outline-gray-400 rounded">
        <div 
            v-for="(item, index) in items" :key="index"
            class="nav-item"
            :class="{'active': currentIndex === index}"
            @click="onClick(index)"
        >
            <div class="p-6">
                <h2 class="font-bold text-uppercase text-lg mb-2">{{ item.title }}</h2>
                <p class="text-2xl font-semibold text-capitalize">{{ item.subtitle }}</p>
            </div>
    </div>
  </div>
</template>

<script>
export default {
    props: {
        items: {
            type: Array
        }
    },
    data() {
        return {
            currentIndex: 0,
        }
    },
    methods: {
        onClick(index) {
            this.currentIndex = index;
            this.$emit('on-click', index);
        }
    }
}
</script>
<style lang="scss">
    .nav-item {
        border-bottom: 1px solid #cbd5e0;
        transition: .3s;
        &:last-child {
            border: none;
        }
        &:hover, &.active {
            cursor: pointer;
            background-color: #c3c3c3;
        }
    }
</style>